

import {

  GET_CLOTHING_STAFF_SAISON_LIST_SUCESS,
  GET_CLOTHING_STAFF_SAISON_LIST,
  GET_ALL_SEASONS_STAFF,
  GET_ALL_SEASONS_STAFF_SUCCESS,
  ADD_CLOTHING_STAFF_SAISON,
  DELETE_CLOTHING_STAFF_SAISON_REQUEST,
  DELETE_CLOTHING_STAFF_SAISON_SUCCESS,
  DELETE_CLOTHING_STAFF_SAISON_FAIL
} from '../constants/ClothingStaffSaison';
export const getClothingStaffSaison = () => {
  return {
    type: GET_CLOTHING_STAFF_SAISON_LIST
  };
};

export const getClothingStaffSaisonSucess = (data) => {
  return {
    type: GET_CLOTHING_STAFF_SAISON_LIST_SUCESS,
    payload: data
  };
};

export const addNewClothingStaffSaison = (data) => {
  return {
    type: ADD_CLOTHING_STAFF_SAISON,
    payload: data
  }
};

export function deleteClothingStaffSaison(data) {
  return {
    type: DELETE_CLOTHING_STAFF_SAISON_REQUEST,
    payload: data
  };
};

export function deleteClothingStaffSaisonSuccess(id) {
  return {
    type: DELETE_CLOTHING_STAFF_SAISON_SUCCESS,
    id
  };
};

export const deleteClothingStaffSaisonFail = () => {
  return {
    type: DELETE_CLOTHING_STAFF_SAISON_FAIL
  };
};

export const getAllSeasonStaff = () => {
  return {
    type: GET_ALL_SEASONS_STAFF
  };
};

export const getAllSeasonStaffSuccess = (seasons) => {
  return {
    type: GET_ALL_SEASONS_STAFF_SUCCESS,
    payload: seasons
  }
};