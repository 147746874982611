import service from 'auth/FetchInterceptor'
import { AUTH_TOKEN } from 'redux/constants/Auth';



const config = {
    headers: { 'authorization': `Bearer ${localStorage.getItem(AUTH_TOKEN)}` }
}
const services = {}
services.getUserInformations = () => service.get('users/me', {

    params: {
        fields: "*, role.*"
    },
})
services.getBrandsData = (page = 1, filter = undefined) => service.get('items/Brand', {
    params: {
        limit: -1,
        filter: {
            ...filter,
            "status": { "_neq": "archived" }
        },
        page,
        meta: "*",
        sort: "-date_created"
    },
    ...config
})
services.getAllBrands = () => service.get(`items/Brand`)
services.deleteBrand = (id) => service.delete(`items/Brand/${id}`)
services.getClientsData = (page = 1, filter = undefined) => service.get('items/Client', {
    params: {
        filter,
        fields: "*,brands*,brands.id, brands.Brand_id.*",
        page,
        limit: 10,
        meta: "*",
        sort: "-date_created"
    },
    ...config
})
services.getAllClientsData = () => service.get('items/Client', {
    params: {
        fields: "*,brands*,brands.id, brands.Brand_id.*",
        meta: "*",
    },
    ...config
})

services.ClothingStaffSaisonData = () => service.get('items/saison_clothing_staff', {
    params: {
        fields: "*,brand,brand.id,brand.Brand_id.*"
    },
    sort: "-date_created",
    ...config
})
services.getAllSeasonStaff = () => service.get('items/saison_clothing_staff', {
    params: {
        fields: "*,brand,brand.id,brand.Brand_id.*",
        filter: {
            "_and": [
                {
                    "active": { "_eq": true }
                }
            ]
        },
        limit: -1,
        meta: "*",
        sort: "-date_created"
    }
})
services.deleteClothingStaffSeasonAPI = (id) => service.delete(`items/saison_clothing_staff/${id}`)
services.getCollaboratorGroupsData = () => service.get('items/Collaborator_group', {
    params: {
        fields: "*,discount_brand,discount_brand.id,discount_brand.Brand_id.*,clothing_staff_brand,clothing_staff_brand.id,clothing_staff_brand.Brand_id.*",
        sort: "-date_created"

    },
    ...config
})
services.deleteCategoryAPI = (id) => service.delete(`items/Collaborator_group/${id}`)

services.getCollaboratorData = (page = 1, filter = undefined) => service.get('items/Collaborator', {
    params: {
        fields: "*,Company.*,category.*, clothing_staff_brand, clothing_staff_brand.id, clothing_staff_brand.Brand_id.*, discount_brand, discount_brand.id, discount_brand.Brand_id.*",
        page,
        filter,
        limit: 10,
        meta: "*",
        sort: "-date_created"
    },
    ...config
})
services.getAllCollaboratorData = () => service.get('items/Collaborator', {
    params: {
        fields: "*,Company.*,category.*, clothing_staff_brand, clothing_staff_brand.id, clothing_staff_brand.Brand_id.*, discount_brand, discount_brand.id, discount_brand.Brand_id.*",
        meta: "*",
        sort: "-date_created",
        limit: -1,
    },
    ...config
})
services.addCollaboratorData = (payload) => service.post(`items/Collaborator`, payload)
services.getCollaboratorById = (id) => service.get(`items/Collaborator/${id}`, {
    params: {
        fields: "*,Company.*,category.*, clothing_staff_brand, clothing_staff_brand.id, clothing_staff_brand.Brand_id.*, discount_brand, discount_brand.id, discount_brand.Brand_id.*",
    },
    ...config
})
services.getSellerData = (page = 1, filter = undefined) => service.get('items/Sellers', {
    params: {
        fields: "*,Brand.*,Brand.id,Brand.Brand_id.*,Collaborator.*,Collaborator.Company.*,Collaborator.category.*, Collaborator.clothing_staff_brand, Collaborator.clothing_staff_brand.id, Collaborator.clothing_staff_brand.Brand_id.*, Collaborator.discount_brand, Collaborator.discount_brand.id, Collaborator.discount_brand.Brand_id.*",
        filter,
        page,
        limit: 10,
        meta: "*",
        sort: "-date_created"
    },
    ...config
})
services.getPurchaseData = (page = 1, filter = undefined) => service.get('items/Purchase', {
    params: {
        fields: "*,brand.*,Brand.id,Brand.Brand_id.*,discount_season.*,clothing_staff_season.*,collaborator.*,guest.*",
        page,
        limit: 10,
        filter: {
            ...filter
        },
        meta: "*",
        sort: "-date_created"
    },
    ...config
})
services.getGuestPurchaseData = () => service.get('items/Purchase', {
    params: {
        limit: -1,
        fields: "*,brand.*,Brand.id,Brand.Brand_id.*,discount_season.*,clothing_staff_season.*,collaborator.*,guest.*",
        filter: {
            "_and": [{ "_and": [{ "status": { "_eq": "done" } }, { "status": { "_neq": "archived" } }, { "collaborator": { "_null": true } }] }]
        },
        meta: "*",
        sort: "-date_created"
    },
    ...config
})

services.getAllPurchaseData = (filter = undefined) => service.get('items/Purchase', {
    params: {
        limit: -1,
        fields: "*,brand.*,Brand.id,Brand.Brand_id.*,discount_season.*,clothing_staff_season.*,collaborator.*,guest.*",
        filter: {
            ...filter,
            status: {
                "_in": ["done", "canceled", "refund"]
            }
        },
        meta: "*",
        sort: "-date_created"

    },
    ...config
})

services.getAllDonePurchaseData = (filter = undefined) => service.get('items/Purchase', {
    params: {
        limit: -1,
        fields: "*,brand.*,Brand.id,Brand.Brand_id.*,discount_season.*,clothing_staff_season.*,collaborator.*,guest.*",
        filter: {
            ...filter,
            status: {
                "_in": ["done"]
            }
        },
        meta: "*",
        sort: "-date_created"

    },
    ...config
})

services.addSellerData = (payload) => service.post(`items/Sellers`, payload)
services.deleteSellerAPI = (id) => service.delete(`items/Sellers/${id}`)
services.getCompanies = () => service.get(`/items/Company`, {
    params: {
        sort: "-date_created"
    },
    ...config
})
services.addCompanies = (payload) => service.post(`/items/Company`, payload)
services.deleteCompaniesAPI = (id) => service.delete(`items/Company/${id}`)
services.getGuestData = (page = 1, filter = undefined) => service.get('items/Guest', {
    params: {
        page,
        filter,
        limit: 10,
        meta: "*",
        fields: "*,brands*,brands.id, brands.Brand_id.*",
        sort: "-date_created"
    },
    ...config
})
services.getGuestById = (id) => service.get(`items/Guest/${id}`, {
    params: {
        meta: "*",
        fields: "*,brands*,brands.id, brands.Brand_id.*"
    },
    ...config
})
services.addGuestData = (payload) => service.post(`items/Guest`, payload)
services.deleteGuestAPI = (id) => service.delete(`items/Guest/${id}`)
services.getSeasonRemise = (page = 1, filter = undefined) => service.get('items/saison_discount', {
    params: {
        fields: "*,brand,brand.id,brand.Brand_id.*",
        filter,
        page,
        limit: 10,
        meta: "*",
        sort: "-date_created"

    }
    , ...config
})
services.getAllSeasonRemise = () => service.get('items/saison_discount', {
    params: {
        fields: "*,brand,brand.id,brand.Brand_id.*",
        filter: {
            "_and": [
                {
                    "active": { "_eq": true }
                }
            ]
        },
        limit: -1,
        meta: "*",
        sort: "-date_created"
    }
})
services.addSeasonRemise = (payload) => service.post(`/items/saison_discount`, payload)


services.getBrandById = (id) => service.get(`items/Brand/${id}`)
services.getClientById = (id) => service.get(`items/Client/${id}`)
services.seasonDiscountById = (id) => service.get(`items/saison_discount/${id}`, {
    params: {
        fields: "*,brand,brand.id,brand.Brand_id.*",
    }
    , ...config
})



const ROLES = [process.env.REACT_APP_ROLE_ADMIN, process.env.REACT_APP_ROLE_SUPER_ADMIN, process.env.REACT_APP_ROLE_SELLER]
services.getUserData = (page = 1) => service.get('/users', {
    params: {
        fields: "*,role.*",
        page,
        filter: {
            "_and": [
                {
                    "role": { "_nin": ROLES }
                }
            ]
        }
    },
    ...config
})
services.getAllUserData = (page = 1) => service.get('/users', {
    params: {
        fields: "*,role.*",
        page
    },
    ...config
})
services.getUserById = (id) => service.get(`/users/${id}`, {
    params: {
        fields: "*,role.*",
        filter: {
            "_and": [
                {
                    "role": { "_nin": ROLES }
                }
            ]
        }
    },
    ...config
})
services.addUserData = (payload) => service.post(`/users`, payload)
services.deleteUserAPI = (id) => service.delete(`/users${id}`)

services.getRoleData = () => service.get('/roles', {

    params: {
        fields: "*,role.*",
        filter: {
            "_and": [
                {
                    "id": { "_nin": ROLES }
                }
            ]
        }
    }
})

services.uploadImage = async (image) => {
    try {
        const data = new FormData()
        data.append(image.name, image)
        const config = {
            headers: {
                "Content-Type": "multipart/form-data;",
            }
        }
        data.append("folder", "579ac3c7-e835-492e-93a5-e90f4b359b47")
        const newLogo = await service.post(`files`, data, config)
        return newLogo.data.id
    } catch (error) {
        throw error
    }

}
services.getSellerById = (id) => service.get(`items/Sellers/${id}`, {
    params: {
        fields: "*,Brand.*,Brand.id,Brand.Brand_id.*,Collaborator.*,Collaborator.Company.*,Collaborator.category.*, Collaborator.clothing_staff_brand, Collaborator.clothing_staff_brand.id, Collaborator.clothing_staff_brand.Brand_id.*, Collaborator.discount_brand, Collaborator.discount_brand.id, Collaborator.discount_brand.Brand_id.*"
    },
    ...config
})

services.getCollaboratorNotSeller = () => service.get('items/Collaborator', {
    params: {

        limit: -1,
        filter: {
            isSeller: {
                "_neq": true
            }
        },
        meta: "*"
    },
    ...config
})



export default services