import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import {
    GET_USERS,
    GET_ALL_USERS,
    ADD_NEW_USER,
    DELETE_USER
} from '../constants/User';
import {
    getUserSuccess,
    getAllUserSuccess,
    addNewUserSuccess,
    deleteUserSuccess
} from "../actions/User";
import services from 'services';

export function* GetUser() {

    yield takeLatest(GET_USERS, function* () {

        try {

            const data = yield call(services.getUserData);

            const fomattedData = data.data
            yield put(getUserSuccess(fomattedData))

        } catch (err) {

        }
    });
}

export function* GetAllUser() {

    yield takeLatest(GET_ALL_USERS, function* () {

        try {

            const data = yield call(services.getAllUserData);

            const fomattedData = data.data
            yield put(getAllUserSuccess(fomattedData))

        } catch (err) {

        }
    });
}

export function* addUser() {
    yield takeEvery(ADD_NEW_USER, function* ({ payload }) {
        try {
            const user = yield call(services.addUserData, payload);
            yield put(addNewUserSuccess(user));

        } catch (err) {
        }
    });
}

export function* deleteUser() {
    yield takeEvery(DELETE_USER, function* ({ payload }) {

        try {
            const user = yield call(services.deleteUserAPI, payload);
            yield put(deleteUserSuccess(user));

        } catch (err) {
        }
    });
}


export default function* rootSaga() {
    yield all([
        fork(GetUser),
        fork(GetAllUser),
        fork(addUser),
        fork(deleteUser),
    ]);
}
