import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import {
  GET_GUEST,
  ADD_NEW_GUEST,
  DELETE_GUEST_REQUEST
} from '../constants/Guest';
import {
  getGuestsSuccess,
  addNewGuestSuccess,
  deleteGuestSuccess
} from "../actions/Guest";
import services from 'services';




export function* GetGuests() {

  yield takeLatest(GET_GUEST, function* ({ page, filter }) {

    try {

      const data = yield call(services.getGuestData, page, filter);

      const fomattedData = data

      yield put(getGuestsSuccess(fomattedData))

    } catch (err) {



    }

  });

}

export function* addGuest() {
  yield takeEvery(ADD_NEW_GUEST, function* ({ payload }) {
    try {
      const guest = yield call(services.addGuestData, payload);
      yield put(addNewGuestSuccess(guest));

    } catch (err) {
    }
  });
}

export function* deleteGuest() {
  yield takeEvery(DELETE_GUEST_REQUEST, function* ({ payload }) {

    try {
      const guest = yield call(services.deleteGuestAPI, payload);
      yield put(deleteGuestSuccess(payload));

    } catch (err) {
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(GetGuests),
    fork(addGuest),
    fork(deleteGuest)
  ]);
}
