import axios from 'axios';
import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';

import {

    GET_CLOTHING_STAFF_SAISON_LIST,
    DELETE_CLOTHING_STAFF_SAISON_REQUEST,
    GET_ALL_SEASONS_STAFF
} from '../constants/ClothingStaffSaison';
import {
    getClothingStaffSaisonSucess,
    deleteClothingStaffSaisonSuccess,
    deleteClothingStaffSaisonFail,
    getAllSeasonStaffSuccess
} from "../actions/ClothingStaffSaison";
import services from 'services';



// const ClothingStaffSaisonData = () => axios.get('items/saison_clothing_staff')
export function* getClothingStaffSaisonList() {


    yield takeLatest(GET_CLOTHING_STAFF_SAISON_LIST, function* () {
        try {
            const ClothingStaffSaison = yield call(services.ClothingStaffSaisonData);

            yield put(getClothingStaffSaisonSucess(ClothingStaffSaison.data));

        } catch (error) {

        }
    });
}

export function* deleteSeasons() {
    yield takeEvery(DELETE_CLOTHING_STAFF_SAISON_REQUEST, function* ({ payload }) {
        try {
            const user = yield call(services.deleteClothingStaffSeasonAPI, payload);
            yield put(deleteClothingStaffSaisonSuccess(payload));

        } catch (err) {
            yield put(deleteClothingStaffSaisonFail(err));
        }
    });
}

export function* getAllActiveSeasonStaff() {

    yield takeEvery(GET_ALL_SEASONS_STAFF, function* () {

        try {

            const data = yield call(services.getAllSeasonStaff);

            const fomattedData = data
            yield put(getAllSeasonStaffSuccess(fomattedData))

        } catch (err) {

        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(getClothingStaffSaisonList),
        fork(deleteSeasons),
        fork(getAllActiveSeasonStaff)
    ]);
}
