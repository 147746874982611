
import {
    GET_COMPANY_REQUEST,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_FAIL,
    ADD_COMPANY_REQUEST,
    ADD_COMPANY_SUCCESS,
    ADD_COMPANY_FAIL,
    GET_COMPANY_ID_REQUEST,
    GET_COMPANY_ID_SUCCESS,
    GET_COMPANY_ID_FAIL,
    DELETE_COMPANY_REQUEST,
    DELETE_COMPANY_SUCCESS,
    DELETE_COMPANY_FAIL,
    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAIL
  } from '../constants/Company';
  
  
  export const getCompanies = () => {
    return {
      type: GET_COMPANY_REQUEST,
    };
  };
  
  export function getCompaniesSuccess(company) {
    return {
      type: GET_COMPANY_SUCCESS,
      payload: company
    };
  };
  
  export const getCompaniesFail = () => {
    return {
      type: GET_COMPANY_FAIL
    };
  };
  
  export const getCompany = (company) => {
    return {
      type: GET_COMPANY_ID_REQUEST,
      payload: company
    };
  };
  
  export function getCompanySuccess(company) {
    return {
      type: GET_COMPANY_ID_SUCCESS,
      payload: company
    };
  };
  
  export const getCompanyFail = () => {
    return {
      type: GET_COMPANY_ID_FAIL
    };
  };
  
  export function addCompanies(company) {
    return {
      type: ADD_COMPANY_REQUEST,
      payload: company
    };
  };
  
  export function addCompaniesSuccess(company) {
    return {
      type: ADD_COMPANY_SUCCESS,
      payload: company
    };
  };
  
  export const addCompaniesFail = () => {
    return {
      type: ADD_COMPANY_FAIL
    };
  };
  
  export function addAllCompanies(company) {
    return {
      type: ADD_COMPANY_REQUEST,
      payload: company
    };
  };
  
  export function addAllCompaniesSuccess(company) {
    return {
      type: ADD_COMPANY_SUCCESS,
      payload: company
    };
  };
  
  export const addAllCompaniesFail = () => {
    return {
      type: ADD_COMPANY_FAIL
    };
  };
  
  export function deleteCompanies(company) {
    return {
      type: DELETE_COMPANY_REQUEST,
      payload: company
    };
  };
  
  export function deleteCompaniesSuccess(company) {
    return {
      type: DELETE_COMPANY_SUCCESS,
      id: company.id
    };
  };
  
  export const deleteCompaniesFail = () => {
    return {
      type: DELETE_COMPANY_FAIL
    };
  };
  
  export function updateCompanies(company) {
    return {
      type: UPDATE_COMPANY_REQUEST,
      payload: company
    };
  };
  
  export function updateCompaniesSuccess(company) {
    
    return {
      type: UPDATE_COMPANY_SUCCESS,
      payload: company
    };
  };
  
  export const updateCompaniesFail = () => {
    return {
      type: UPDATE_COMPANY_FAIL
    };
  };
  