export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAIL = 'GET_COMPANY_FAIL';
export const GET_COMPANY_ID_REQUEST = 'GET_COMPANY_ID_REQUEST';
export const GET_COMPANY_ID_SUCCESS = 'GET_COMPANY_ID_SUCCESS';
export const GET_COMPANY_ID_FAIL = 'GET_COMPANY_ID_FAIL';
export const ADD_COMPANY_REQUEST = 'ADD_COMPANY_REQUEST';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAIL = 'ADD_COMPANY_FAIL';
export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';
export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';
export const ADD_ALL_COMPANY_REQUEST = 'ADD_ALL_COMPANY_REQUEST';
export const ADD_ALL_COMPANY_SUCCESS = 'ADD_ALL_COMPANY_SUCCESS';
export const ADD_ALL_COMPANY_FAIL = 'ADD_ALL_COMPANY_FAIL';