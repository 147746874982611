import React from 'react'
import { APP_NAME } from 'configs/AppConfig';

export default function Footer() {
	return (
		<footer className="footer">
			 <span className="font-weight-semibold">Indigo © {`${new Date().getFullYear()}`}</span> 
		</footer>
	)
}

