import { GET_PURCHASES, GET_ALL_DONE_PURCHASES, GET_ALL_DONE_PURCHASES_SUCCESS, GET_PURCHASES_SUCCESS, GET_ALL_PURCHASES, GET_ALL_PURCHASES_SUCCESS, GET_GUEST_PURCHASES, GET_GUEST_PURCHASES_SUCCESS } from "redux/constants/Purchase";

export const getPurchases = (page, filter) => {
    return {
        type: GET_PURCHASES,
        page,
        filter
    };
};

export const getPurchaseSuccess = (purchases) => {
    return {
        type: GET_PURCHASES_SUCCESS,
        payload: purchases
    }
};

export const getGuestPurchases = (page, filter) => {
    return {
        type: GET_GUEST_PURCHASES,
        page,
        filter
    };
};

export const getGuestPurchaseSuccess = (purchases) => {
    return {
        type: GET_GUEST_PURCHASES_SUCCESS,
        payload: purchases
    }
};

export const getAllPurchases = (page, filter) => {
    return {
        type: GET_ALL_PURCHASES,
        page,
        filter
    };
};

export const getAllPurchaseSuccess = (purchases) => {
    return {
        type: GET_ALL_PURCHASES_SUCCESS,
        payload: purchases
    }
};

export const getAllDonePurchases = (page, filter) => {
    return {
        type: GET_ALL_DONE_PURCHASES,
        page,
        filter
    };
};

export const getAllDonePurchaseSuccess = (purchases) => {
    return {
        type: GET_ALL_DONE_PURCHASES_SUCCESS,
        payload: purchases
    }
};