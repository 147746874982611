import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import {
    GET_PURCHASES,
    GET_ALL_PURCHASES,
    GET_GUEST_PURCHASES,
    GET_ALL_DONE_PURCHASES
} from '../constants/Purchase';
import {
    getPurchaseSuccess,
    getAllPurchaseSuccess,
    getGuestPurchaseSuccess,
    getAllDonePurchaseSuccess
} from "../actions/Purchase";
import services from 'services';

export function* GetPurchase() {

    yield takeLatest(GET_PURCHASES, function* ({ page, filter }) {

        try {

            const data = yield call(services.getPurchaseData, page, filter);

            const fomattedData = data
            yield put(getPurchaseSuccess(fomattedData))

        } catch (err) {

        }
    });
}

export function* GetGuestPurchase() {

    yield takeEvery(GET_GUEST_PURCHASES, function* ({ page, filter }) {

        try {

            const data = yield call(services.getGuestPurchaseData, page, filter);

            const fomattedData = data
            yield put(getGuestPurchaseSuccess(fomattedData))

        } catch (err) {

        }
    });
}

export function* GetAllPurchase() {

    yield takeEvery(GET_ALL_PURCHASES, function* ({ page, filter }) {

        try {

            const data = yield call(services.getAllPurchaseData, page, filter);

            const fomattedData = data
            yield put(getAllPurchaseSuccess(fomattedData))

        } catch (err) {

        }
    });
}

export function* GetAllDonePurchase() {

    yield takeEvery(GET_ALL_DONE_PURCHASES, function* ({ page, filter }) {

        try {

            const data = yield call(services.getAllDonePurchaseData, page, filter);

            const fomattedData = data
            yield put(getAllDonePurchaseSuccess(fomattedData))

        } catch (err) {

        }
    });
}


export default function* rootSaga() {
    yield all([
        fork(GetPurchase),
        fork(GetAllPurchase),
        fork(GetGuestPurchase),
        fork(GetAllDonePurchase)
    ]);
}
