import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import {
	GET_BRANDS,
	DELETE_BRAND_REQUEST,
	DELETE_BRAND_SUCCESS

} from '../constants/brands';
import {
	getBrandsSuccess,
	deleteCategoryAction,
	deleteBrandSuccess,
	deleteBrandfail
} from "../actions/brands";
import services from 'services';
import { message } from 'antd';




export function* GetBrands() {
	yield takeLatest([GET_BRANDS], function* ({ page, filters, limit }) {

		try {

			const data = yield call(services.getBrandsData, page, filters, limit);
			yield put(getBrandsSuccess(data))
		} catch (err) {

		}
	});
}
export function* deleteBrand() {
	yield takeEvery(DELETE_BRAND_REQUEST, function* ({ payload }) {

		try {
			const user = yield call(services.deleteBrand, payload);
			yield put(deleteBrandSuccess(payload));

		} catch (err) {
			yield put(deleteBrandfail(err));
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(GetBrands),
		fork(deleteBrand),

	]);
}
