
import {
    GET_GUEST,
    GET_GUEST_SUCCESS,
    ADD_NEW_GUEST,
    ADD_NEW_GUEST_SUCCESS,
    DELETE_GUEST_REQUEST,
    DELETE_GUEST_SUCCESS
} from '../constants/Guest';

const initState = {
    loading: false,
    guests: null,
    deleted: false,
    count: 0
}

const deleteGuest = (guest, guestIdToDelete) => {
    const newGuest = [...guest]
    const id = newGuest.map(el => el.id).indexOf(guestIdToDelete)
    newGuest.splice(id, 1)
    return newGuest
}

const guests = (state = initState, action) => {
    switch (action.type) {
        case GET_GUEST:
            return {
                ...state,
                loading: true
            }
        case GET_GUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                guests: action.payload.data,
                count: action.payload.meta?.filter_count
            }
        case ADD_NEW_GUEST:
            return {
                ...state,
                loading: true
            }
        case ADD_NEW_GUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                guests: [action.payload, ...state.guests]
            }
        case DELETE_GUEST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case DELETE_GUEST_SUCCESS:
            return {
                loading: false,
                deleted: true,
                guests: deleteGuest(state.guests, action.id)
            }
        default:
            return state;
    }
}

export default guests