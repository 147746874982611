import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import {
	GET_ME
} from '../constants/CurrentUserProfile';
import {
	getMeSuccess
} from "../actions/CurrentUserProfile";
import services from 'services';
import { message } from 'antd';

export function* GetUserInfo() {
	yield takeEvery(GET_ME, function* () {
		try {
			const user = yield call(services.getUserInformations);
			const fomattedData = user.data
			yield put(getMeSuccess(fomattedData))
		} catch (err) {
			message.error("Une erreur est survenue")
			throw err
		}
	});
}

export default function* rootSaga() {
	yield all([
		fork(GetUserInfo),
	]);
}