
import {
    GET_ME,
    GET_ME_SUCCESS
     
 } from '../constants/CurrentUserProfile';
 
 const initState = {
   loading: false,
   profile: null
 }
 
 const CurrentUserProfile = (state = initState, action) => {
     switch (action.type) {
         case GET_ME:
             return {
                 ...state,
                 loading: true
             }
             case GET_ME_SUCCESS:
             return {
                 ...state,
                 loading: false,
                 profile: action.payload
             }
             
                  
         default:
             return state;
     }
 }
 
 export default CurrentUserProfile