export const GET_SEASON_REMISE_REQUEST = 'GET_SEASON_REMISE_REQUEST';
export const GET_SEASON_REMISE_SUCCESS = 'GET_SEASON_REMISE_SUCCESS';
export const GET_SEASON_REMISE_FAIL = 'GET_SEASON_REMISE_FAIL';
export const GET_ALL_SEASONS_REMISE = 'GET_ALL_SEASONS_REMISE';
export const GET_ALL_SEASONS_REMISE_SUCCESS = 'GET_ALL_SEASONS_REMISE_SUCCESS';
export const GET_SEASON_REMISE_ID_REQUEST = 'GET_SEASON_REMISE_ID_REQUEST';
export const GET_SEASON_REMISE_ID_SUCCESS = 'GET_SEASON_REMISE_ID_SUCCESS';
export const GET_SEASON_REMISE_ID_FAIL = 'GET_SEASON_REMISE_ID_FAIL';
export const ADD_SEASON_REMISE_REQUEST = 'ADD_SEASON_REMISE_REQUEST';
export const ADD_SEASON_REMISE_SUCCESS = 'ADD_SEASON_REMISE_SUCCESS';
export const ADD_SEASON_REMISE_FAIL = 'ADD_SEASON_REMISE_FAIL';
export const DELETE_SEASON_REMISE_REQUEST = 'DELETE_SEASON_REMISE_REQUEST';
export const DELETE_SEASON_REMISE_SUCCESS = 'DELETE_SEASON_REMISE_SUCCESS';
export const DELETE_SEASON_REMISE_FAIL = 'DELETE_SEASON_REMISE_FAIL';
export const UPDATE_SEASON_REMISE_REQUEST = 'UPDATE_SEASON_REMISE_REQUEST';
export const UPDATE_SEASON_REMISE_SUCCESS = 'UPDATE_SEASON_REMISE_SUCCESS';
export const UPDATE_SEASON_REMISE_FAIL = 'UPDATE_SEASON_REMISE_FAIL';
export const ADD_ALL_SEASON_REMISE_REQUEST = 'ADD_ALL_SEASON_REMISE_REQUEST';
export const ADD_ALL_SEASON_REMISE_SUCCESS = 'ADD_ALL_SEASON_REMISE_SUCCESS';
export const ADD_ALL_SEASON_REMISE_FAIL = 'ADD_ALL_SEASON_REMISE_FAIL';