import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import {
	GET_CLIENTS,
	GET_ALL_CLIENTS
} from '../constants/Clients';
import {
	getClientsSuccess,
	getAllClientsSuccess
} from "../actions/Client";
import services from 'services';


export function* getClientsAction() {
	yield takeLatest(GET_CLIENTS, function* ({ page, filter }) {

		try {

			const data = yield call(services.getClientsData, page, filter);
			const fomattedData = data
			yield put(getClientsSuccess(fomattedData))
		} catch (err) {

		}
	});
}

export function* getAllClientsAction() {
	yield takeEvery(GET_ALL_CLIENTS, function* ({ page, filter }) {

		try {

			const data = yield call(services.getAllClientsData, page, filter);
			const fomattedData = data
			yield put(getAllClientsSuccess(fomattedData))
		} catch (err) {

		}
	});
}
export default function* rootSaga() {
	yield all([
		fork(getClientsAction),
		fork(getAllClientsAction)
	]);
}
