import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import CollaboratorGroup from './CollaboratorGroup';
import ClothingStaffSaison from './ClothingStaffSaison';
import Company from './Company';
import Users from './Users';
import Season from './Season';
import Brands from './brands';
import Collaborators from "./collaborator"
import Sellers from "./seller"
import Clients from "./Clients"
import Guests from './Guest'
import Roles from './Roles'
import Purchases from './Purchases'
import CurrentUserProfile from './CurrentUserProfile'

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    collaboratorGroup: CollaboratorGroup,
    clothingStaffSaison: ClothingStaffSaison,
    company: Company,
    season: Season,
    brands: Brands,
    collaborators: Collaborators,
    sellers: Sellers,
    clients: Clients,
    guests: Guests,
    users: Users,
    roles: Roles,
    purchases: Purchases,
    currentUser: CurrentUserProfile
});

export default reducers;