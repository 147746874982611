import {

	GET_CLOTHING_STAFF_SAISON_LIST_SUCESS,
	GET_CLOTHING_STAFF_SAISON_LIST,
	GET_ALL_SEASONS_STAFF_SUCCESS,
	ADD_CLOTHING_STAFF_SAISON,
	DELETE_CLOTHING_STAFF_SAISON_REQUEST,
	DELETE_CLOTHING_STAFF_SAISON_SUCCESS,
	DELETE_CLOTHING_STAFF_SAISON_FAIL
} from '../constants/ClothingStaffSaison';

const initState = {
	loading: false,
	deleted: false,
	ClothingStaffSaisonData: null,
	allSeasonStaff: null
}

const deleteSeason = (seasons, seasonIdToDelete) => {
	const newSeason = [...seasons]
	const id = newSeason.map(el => el.id).indexOf(seasonIdToDelete)
	newSeason.splice(id, 1)
	return newSeason
}

const ClothingStaffSaison = (state = initState, action) => {
	switch (action.type) {
		case GET_CLOTHING_STAFF_SAISON_LIST:
			return {
				...state,
				loading: true,
			}
		case GET_CLOTHING_STAFF_SAISON_LIST_SUCESS:
			return {
				...state,
				loading: false,
				ClothingStaffSaisonData: action.payload
			}
		case ADD_CLOTHING_STAFF_SAISON:
			return {
				...state,
				loading: false
			}
		case DELETE_CLOTHING_STAFF_SAISON_REQUEST:
			return {
				...state,
				loading: true
			}
		case DELETE_CLOTHING_STAFF_SAISON_SUCCESS:
			return {
				loading: false,
				deleted: true,
				ClothingStaffSaisonData: deleteSeason(state.ClothingStaffSaisonData, action.id)
			}
		case DELETE_CLOTHING_STAFF_SAISON_FAIL:
			return {
				loading: false,
				deleted: false
			}
		case GET_ALL_SEASONS_STAFF_SUCCESS:
			return { ...state, loading: false, allSeasonStaff: action.payload?.data }
		default:
			return state;
	}
}

export default ClothingStaffSaison