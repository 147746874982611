import { GET_SELLERS, GET_SELLERS_SUCCESS, ADD_NEW_SELLER, ADD_NEW_SELLER_SUCCESS, DELETE_SELLER_REQUEST, DELETE_SELLER_SUCCESS, DELETE_SELLER_FAIL } from "redux/constants/sellers";

export const getSellers= (page, filter) => {
    return {
      type: GET_SELLERS,
      page,
      filter
    };
  };
  
  export const getSellerSuccess = (sellers) => {
    return {
      type: GET_SELLERS_SUCCESS,
      payload: sellers
    }
  };
  export const addNewSeller = (seller) => {
    return {
      type: ADD_NEW_SELLER,
      payload: seller
    }
  };
  export function addNewSellerSuccess(seller) {
    return {
      type: ADD_NEW_SELLER_SUCCESS,
      payload: seller
    };
  };
  export function deleteSellerAction(seller) {
    return {
      type: DELETE_SELLER_REQUEST,
      payload: seller
    };
  };
  export function deleteSellerSuccess(seller) {
    return {
      type: DELETE_SELLER_SUCCESS,
      id: seller.id
    };
  };
  
  export const deleteSellerFail = () => {
    return {
      type: DELETE_SELLER_FAIL
    };
  };