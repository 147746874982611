import { GET_COLLABORATORS, GET_COLLABORATORS_SUCCESS, ADD_NEW_COLLABORATOR, ADD_NEW_COLLABORATOR_SUCCESS, GET_ALL_COLLABORATORS, GET_ALL_COLLABORATORS_SUCCESS } from "redux/constants/collaborator";

export const getCollaborators = (page, filter) => {
  return {
    type: GET_COLLABORATORS,
    page,
    filter
  };
};

export const getCollaboratorSuccess = (collaborators) => {

  return {
    type: GET_COLLABORATORS_SUCCESS,
    payload: collaborators
  }
};

export const getAllCollaborators = () => {
  return {
    type: GET_ALL_COLLABORATORS,
  };
};

export const getAllCollaboratorSuccess = (collaborators) => {

  return {
    type: GET_ALL_COLLABORATORS_SUCCESS,
    payload: collaborators
  }
};
export const addNewCollaborator = (collaborateur) => {
  return {
    type: ADD_NEW_COLLABORATOR,
    payload: collaborateur
  }
};

export function addNewCollaboratorSuccess(collaborateur) {
  return {
    type: ADD_NEW_COLLABORATOR_SUCCESS,
    payload: collaborateur
  };
};