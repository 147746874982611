
import {
	ADD_NEW_BRAND,
	GET_BRANDS,
	GET_BRANDS_SUCCESS,
	DELETE_BRAND_REQUEST,
	DELETE_BRAND_SUCCESS,
	DELETE_BRAND_FAIL,
} from '../constants/brands';

const initState = {
	loading: false,
	brands: null,
	count:0
}
const deleteBrand = (category, categoryIdToDelete) => {
	const newCategory = [...category]
	const id = newCategory.map(el => el.id).indexOf(categoryIdToDelete)
	newCategory.splice(id, 1)
	return newCategory
}
const brands = (state = initState, action) => {
	switch (action.type) {
		case GET_BRANDS:
			return {
				...state,
				loading: true
			}
		case GET_BRANDS_SUCCESS:
			return {
				...state,
				loading: false,
				brands: action.payload.data,
				count:action.payload.meta?.filter_count
			}
		case ADD_NEW_BRAND:
			return {
				...state,
				loading: false,
			}
		case DELETE_BRAND_REQUEST:
			return { ...state, loading: true }
		case DELETE_BRAND_SUCCESS:
			return { loading: false, deleted: true, brands: deleteBrand(state.brands, action.id), count : state.count - 1 }
		case DELETE_BRAND_FAIL:
			return { loading: false, deleted: false }
		default:
			return state;
	}
}

export default brands