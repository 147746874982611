
import {
    GET_CLIENTS,
    GET_CLIENTS_SUCCESS,
    GET_ALL_CLIENTS,
    GET_ALL_CLIENTS_SUCCESS
} from '../constants/Clients';

const initState = {
    loading: false,
    clients: null,
    allClients: null,
    count: 0
}

const Clients = (state = initState, action) => {
    switch (action.type) {
        case GET_CLIENTS:
            return {
                ...state,
                loading: true
            }
        case GET_CLIENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                clients: action.payload.data,
                count: action.payload.meta.filter_count
            }
        case GET_ALL_CLIENTS:
            return {
                ...state,
                loading: true
            }
        case GET_ALL_CLIENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                allClients: action.payload.data,
                count: action.payload.meta.filter_count
            }

        default:
            return state;
    }
}

export default Clients