import { AUTH_PREFIX_PATH } from 'configs/AppConfig';
import {

	GET_COLLABORATOR_GROUP_LIST_SUCESS,
	GET_COLLABORATOR_GROUP_LIST,
	ADD_NEW_COLLABORATOR_GROUP,
	DELETE_CATEGORY_FAIL,
	DELETE_CATEGORY_SUCCESS,
	DELETE_CATEGORY_REQUEST
} from '../constants/CollaboratorGroup';

const initState = {
	loading: false,
	collaboratorGroupData: null,
	deleted: false


}
const deleteCategory = (category, categoryIdToDelete) => {
	const newCategory = [...category]
	const id = newCategory.map(el => el.id).indexOf(categoryIdToDelete)
	newCategory.splice(id, 1)
	return newCategory
}


const CollaboratorGroup = (state = initState, action) => {
	switch (action.type) {
		case GET_COLLABORATOR_GROUP_LIST:
			return {
				...state,
				loading: true,
			}
		case GET_COLLABORATOR_GROUP_LIST_SUCESS:
			return {
				...state,
				loading: false,
				collaboratorGroupData: action.payload
			}
		case ADD_NEW_COLLABORATOR_GROUP:
			return {
				...state,
				loading: false
			}
		case DELETE_CATEGORY_REQUEST:
			return { ...state, loading: true }
		case DELETE_CATEGORY_SUCCESS:
			return { loading: false, deleted: true, collaboratorGroupData: deleteCategory(state.collaboratorGroupData, action.id) }
		case DELETE_CATEGORY_FAIL:
			return { loading: false, deleted: false }
		default:
			return state;
	}
}

export default CollaboratorGroup