
import {
	GET_COLLABORATORS,
	GET_COLLABORATORS_SUCCESS,
	GET_ALL_COLLABORATORS_SUCCESS,
	ADD_NEW_COLLABORATOR,
	ADD_NEW_COLLABORATOR_SUCCESS
} from '../constants/collaborator';

const initState = {
	loading: false,
	collaborators: null,
	allCollaborators: null,
	count: null
}

const collaborators = (state = initState, action) => {
	switch (action.type) {
		case GET_COLLABORATORS:
			return {
				...state,
				loading: true
			}
		case GET_COLLABORATORS_SUCCESS:
			return {
				...state,
				loading: false,
				collaborators: action.payload.data,
				count: action.payload.meta?.filter_count
			}
		case GET_ALL_COLLABORATORS_SUCCESS:
			return {
				...state,
				loading: false,
				allCollaborators: action.payload?.data,
			}
		case ADD_NEW_COLLABORATOR:
			return {
				...state,
				loading: true
			}
		case ADD_NEW_COLLABORATOR_SUCCESS:
			return {
				...state,
				loading: false,
				collaborators: [action.payload, ...state.collaborators]
			}
		default:
			return state;
	}
}

export default collaborators