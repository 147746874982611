import {
  DashboardOutlined,
  BankOutlined,
  SkinOutlined,
  UserSwitchOutlined,
  UserOutlined,
  AppstoreOutlined,
  DeploymentUnitOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  PercentageOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import { GROUP_1, GROUP_2, GROUP_3, GROUP_4, ADMIN, SUPER_ADMIN } from 'constants/RolesConstants'



const dashBoardNavTree = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'Acceuil',
  icon: DashboardOutlined,
  breadcrumb: false,
  showInMenu: true,
  componentPath: './home/index.js',
  roles: [GROUP_1, GROUP_2, GROUP_4],
  submenu: []
},
{
  key: 'purchases',
  path: `${APP_PREFIX_PATH}/purchases`,
  title: 'purchases',
  icon: ShoppingCartOutlined,
  breadcrumb: false,
  showInMenu: true,
  componentPath: './purchases/index.js',
  roles: [GROUP_1, GROUP_2, GROUP_3, GROUP_4],
  submenu: [],
  index: 1
},
{
  key: 'users',
  path: `${APP_PREFIX_PATH}/users`,
  title: 'administrators',
  icon: UserOutlined,
  breadcrumb: false,
  showInMenu: true,
  componentPath: './users/index.js',
  roles: [ADMIN, SUPER_ADMIN],
  submenu: [],
  index: 2
},
{
  key: 'users_update',
  path: `${APP_PREFIX_PATH}/users/:id`,
  title: 'administrators',
  icon: UserOutlined,
  breadcrumb: false,
  showInMenu: false,
  componentPath: './users/updateUser/index.js',
  roles: [ADMIN, SUPER_ADMIN],
  submenu: [],
  index: 3
},
{
  key: 'collaborators',
  path: `${APP_PREFIX_PATH}/collaborators`,
  title: 'collaborators',
  icon: UserOutlined,
  breadcrumb: false,
  showInMenu: true,
  componentPath: './collaborators/index.js',
  roles: [GROUP_1, GROUP_4],
  submenu: [],
  index: 4
},
{
  key: 'collaborators_update',
  path: `${APP_PREFIX_PATH}/collaborators/:id`,
  title: 'collaborators',
  icon: UserOutlined,
  breadcrumb: false,
  showInMenu: false,
  componentPath: './collaborators/updateCollaborator/index.js',
  roles: [GROUP_1],
  submenu: [],
  index: 5
},
{
  key: 'sellers',
  path: `${APP_PREFIX_PATH}/sellers`,
  title: 'sellers',
  icon: UserSwitchOutlined,
  breadcrumb: false,
  showInMenu: true,
  componentPath: './sellers/index.js',
  roles: [GROUP_1, GROUP_4],
  submenu: [],
  index: 6
},
{
  key: 'sellers_update',
  path: `${APP_PREFIX_PATH}/sellers/:id`,
  title: 'sellers',
  icon: UserSwitchOutlined,
  breadcrumb: false,
  showInMenu: false,
  componentPath: './sellers/updateSeller/index.js',
  roles: [GROUP_1],
  submenu: [],
  index: 7
},
{
  key: 'brands',
  path: `${APP_PREFIX_PATH}/brands`,
  title: 'brands',
  icon: ShopOutlined,
  breadcrumb: false,
  showInMenu: true,
  componentPath: './brands/index.js',
  roles: [GROUP_1, GROUP_4],
  submenu: [],
  index: 8
},
{
  key: 'brands_update',
  path: `${APP_PREFIX_PATH}/brands/:id`,
  title: 'brands',
  icon: ShopOutlined,
  breadcrumb: false,
  showInMenu: false,
  componentPath: './brands/updateBrand/index.js',
  roles: [GROUP_1],
  submenu: [],
  index: 9
},
{
  key: 'categories',
  path: `${APP_PREFIX_PATH}/collaborator_group`,
  title: 'collaborator_group',
  icon: DeploymentUnitOutlined,
  breadcrumb: false,
  showInMenu: true,
  componentPath: './category',
  roles: [GROUP_1, GROUP_4],
  submenu: [],
  index: 10
},
{
  key: 'categories_update',
  path: `${APP_PREFIX_PATH}/collaborator_group/:id`,
  title: 'category',
  icon: DeploymentUnitOutlined,
  breadcrumb: false,
  showInMenu: false,
  componentPath: './category/updateCollaboratorGroup/index.js',
  roles: [GROUP_1],
  submenu: [],
  index: 11
},
{
  key: 'companies',
  path: `${APP_PREFIX_PATH}/companies`,
  title: 'companies',
  icon: BankOutlined,
  breadcrumb: false,
  showInMenu: true,
  componentPath: './companies/index.js',
  roles: [GROUP_1, GROUP_4],
  submenu: [],
  index: 12
},
{
  key: 'companies_update',
  path: `${APP_PREFIX_PATH}/companies/:id`,
  title: 'companies',
  icon: BankOutlined,
  breadcrumb: false,
  showInMenu: false,
  componentPath: './companies/update-companies/index.js',
  roles: [GROUP_1],
  submenu: [],
  index: 13
},
{
  key: 'clients',
  path: `${APP_PREFIX_PATH}/clients`,
  title: 'clients',
  icon: UserOutlined,
  breadcrumb: false,
  showInMenu: true,
  componentPath: './clients/index.js',
  roles: [GROUP_1, GROUP_4],
  submenu: [],
  index: 14
},
{
  key: 'clients_details',
  path: `${APP_PREFIX_PATH}/clients/:id`,
  title: 'clients',
  icon: UserOutlined,
  breadcrumb: false,
  showInMenu: false,
  componentPath: './clients/detailsClients/index.js',
  roles: [GROUP_1, GROUP_4],
  submenu: [],
  index: 15
},
{
  key: '',
  path: ``,
  title: 'Invités',
  icon: AppstoreOutlined,
  breadcrumb: false,
  showInMenu: true,
  roles: [GROUP_1, GROUP_4],
  submenu: [
    {
      key: 'guests',
      path: `${APP_PREFIX_PATH}/guests`,
      title: 'guests',
      icon: UserAddOutlined,
      breadcrumb: false,
      showInMenu: true,
      componentPath: './guests/index.jsx',
      roles: [GROUP_1, GROUP_4],
      submenu: [],
      index: 16
    },
    {
      key: 'guests_update',
      path: `${APP_PREFIX_PATH}/guests/:id`,
      title: 'guests',
      icon: UserAddOutlined,
      breadcrumb: false,
      showInMenu: false,
      componentPath: './guests/updateGuest/index.jsx',
      roles: [GROUP_1],
      submenu: [],
      index: 17
    },
    {
      key: 'guest_purchases',
      path: `${APP_PREFIX_PATH}/guest_purchases`,
      title: 'guest_purchases',
      icon: ShoppingCartOutlined,
      breadcrumb: false,
      showInMenu: true,
      componentPath: './guests/guestPurchases/index.js',
      roles: [GROUP_1, GROUP_4],
      submenu: [],
      index: 16
    }]
},
{
  key: '',
  path: ``,
  title: 'Saisons',
  icon: AppstoreOutlined,
  breadcrumb: false,
  showInMenu: true,
  roles: [GROUP_1, GROUP_4],
  submenu: [
    {
      key: 'stuff',
      path: `${APP_PREFIX_PATH}/clothing-stuff`,
      title: 'stuff',
      icon: SkinOutlined,
      breadcrumb: false,
      showInMenu: true,
      componentPath: './clothing-staff-seasons',
      roles: [GROUP_1, GROUP_4],
      submenu: [],
      index: 18
    },
    {
      key: 'stuff_update',
      path: `${APP_PREFIX_PATH}/clothing-stuff/:id`,
      title: 'stuff',
      icon: SkinOutlined,
      breadcrumb: false,
      showInMenu: false,
      componentPath: './clothing-staff-seasons/update-clothing-staff-seasons/index.js',
      roles: [GROUP_1],
      submenu: [],
      index: 19
    },
    {
      key: 'discount',
      path: `${APP_PREFIX_PATH}/season-discount`,
      title: 'discount',
      icon: PercentageOutlined,
      breadcrumb: false,
      showInMenu: true,
      componentPath: './season-discount/index.js',
      roles: [GROUP_1, GROUP_4],
      submenu: [],
      index: 20
    },
    {
      key: 'discount_update',
      path: `${APP_PREFIX_PATH}/season-discount/:id`,
      title: 'discount',
      icon: PercentageOutlined,
      breadcrumb: false,
      showInMenu: false,
      componentPath: './season-discount/update-season-discount/index.js',
      roles: [GROUP_1],
      submenu: [],
      index: 21
    }
  ]
}

]

const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;
