
import {
    GET_PURCHASES,
    GET_PURCHASES_SUCCESS,
    GET_ALL_PURCHASES_SUCCESS,
    GET_GUEST_PURCHASES,
    GET_GUEST_PURCHASES_SUCCESS,
    GET_ALL_DONE_PURCHASES_SUCCESS
} from '../constants/Purchase';

const initState = {
    loading: false,
    purchases: null,
    guestPurchases: null,
    allPurchases: null,
    allDonePurchases: null,
    count: 0
}



const purchases = (state = initState, action) => {
    switch (action.type) {
        case GET_PURCHASES:
            return {
                ...state,
                loading: true
            }
        case GET_PURCHASES_SUCCESS:
            return {
                ...state,
                loading: false,
                purchases: action.payload?.data,
                count: action.payload?.meta?.filter_count
            }
        case GET_GUEST_PURCHASES:
            return {
                ...state,
                loading: true
            }
        case GET_GUEST_PURCHASES_SUCCESS:
            return {
                ...state,
                loading: false,
                guestPurchases: action.payload?.data,
                count: action.payload?.meta?.filter_count
            }
        case GET_ALL_PURCHASES_SUCCESS:
            return {
                ...state,
                loading: false,
                allPurchases: action.payload?.data,
                count: action.payload?.meta?.filter_count
            }
        case GET_ALL_DONE_PURCHASES_SUCCESS:
            return {
                ...state,
                loading: false,
                allDonePurchases: action.payload?.data,
                count: action.payload?.meta?.filter_count
            }
        default:
            return state;
    }
}

export default purchases