import {
   GET_ME,
   GET_ME_SUCCESS
  } from "redux/constants/CurrentUserProfile";

export const getMe = () => {
    return {
      type: GET_ME
    };
  };
  
  export const getMeSuccess = (profile) => {
    return {
      type: GET_ME_SUCCESS,
      payload: profile
    }
  }; 