import { all } from 'redux-saga/effects';
import CollaboratorsGroup from './CollaboratorGroups';
import ClothingStaffSaison from './ClothingStaffSaison';
import Company from './Company';
import Season from './Season';
import Auth from './Auth';
import Brands from './brands'
import Collaborators from "./collaborators"
import Sellers from "./sellers"
import Clients from "./Clients"
import Guests from './Guest'
import Users from './Users'
import Roles from './Roles'
import Purchases from './Purchases'
import GetUserInfo from './CurrentUserProfile';

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    CollaboratorsGroup(),
    ClothingStaffSaison(),
    Company(),
    Season(),
    Brands(),
    Collaborators(),
    Sellers(),
    Clients(),
    Guests(),
    Users(),
    Roles(),
    Purchases(),
    GetUserInfo()
  ]);
}
