import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import {
  ADD_NEW_COLLABORATOR,
  GET_COLLABORATORS,
  GET_ALL_COLLABORATORS
} from '../constants/collaborator';
import {
  addNewCollaboratorSuccess,
  getCollaboratorSuccess,
  getAllCollaboratorSuccess
} from "../actions/collaborator";
import services from 'services';



export function* GetCollaborator() {

  yield takeLatest(GET_COLLABORATORS, function* ({ page, filter }) {

    try {

      const data = yield call(services.getCollaboratorData, page, filter);

      const fomattedData = data

      yield put(getCollaboratorSuccess(fomattedData))

    } catch (err) {



    }

  });

}
export function* addCollaborator() {
  yield takeEvery(ADD_NEW_COLLABORATOR, function* ({ payload }) {
    try {
      const collaborator = yield call(services.addCollaboratorData, payload);
      yield put(addNewCollaboratorSuccess(collaborator));

    } catch (err) {
    }
  });
}
export function* GetAllCollaborator() {

  yield takeEvery(GET_ALL_COLLABORATORS, function* () {

    try {

      const data = yield call(services.getAllCollaboratorData);

      const fomattedData = data
      yield put(getAllCollaboratorSuccess(fomattedData))

    } catch (err) {

    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(GetCollaborator),
    fork(addCollaborator),
    fork(GetAllCollaborator)
  ]);
}
