import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import {
    GET_SEASON_REMISE_REQUEST,
    GET_ALL_SEASONS_REMISE,
    ADD_SEASON_REMISE_REQUEST,
} from '../constants/Season';
import {
    getSeasonRemiseSuccess,
    getSeasonRemiseFail,
    addSeasonRemiseSuccess,
    addSeasonRemiseFail,
    getAllSeasonRemiseSuccess
} from "../actions/Season";
import services from 'services';





export function* getAllSeasonRemise() {
    yield takeLatest(GET_SEASON_REMISE_REQUEST, function* ({ page, filter }) {
        try {
            const data = yield call(services.getSeasonRemise, page, filter);
            yield put(getSeasonRemiseSuccess(data));

        } catch (err) {
            yield put(getSeasonRemiseFail(err));
        }
    });
}


export function* addAllSeasonRemise() {
    yield takeEvery(ADD_SEASON_REMISE_REQUEST, function* ({ payload }) {
        try {
            const user = yield call(services.addSeasonRemise, payload);
            yield put(addSeasonRemiseSuccess(user));

        } catch (err) {
            yield put(addSeasonRemiseFail(err));
        }
    });
}

export function* getAllActiveSeasonRemise() {

    yield takeEvery(GET_ALL_SEASONS_REMISE, function* () {

        try {

            const data = yield call(services.getAllSeasonRemise);

            const fomattedData = data
            yield put(getAllSeasonRemiseSuccess(fomattedData))

        } catch (err) {

        }
    });
}

export default function* rootSaga() {
    yield all([
        fork(getAllSeasonRemise),
        fork(addAllSeasonRemise),
        fork(getAllActiveSeasonRemise)
    ]);
}
