
import {
    GET_COMPANY_REQUEST,
    GET_COMPANY_SUCCESS,
    GET_COMPANY_FAIL,
    GET_COMPANY_ID_REQUEST,
    GET_COMPANY_ID_SUCCESS,
    GET_COMPANY_ID_FAIL,
    ADD_COMPANY_REQUEST,
    ADD_COMPANY_SUCCESS,
    ADD_COMPANY_FAIL,
    DELETE_COMPANY_REQUEST,
    DELETE_COMPANY_SUCCESS,
    DELETE_COMPANY_FAIL,
    UPDATE_COMPANY_REQUEST,
    UPDATE_COMPANY_SUCCESS,
    UPDATE_COMPANY_FAIL
} from '../constants/Company';

const deleteCompany = (companies, companyIdToDelete) => {
    const newCompanies = [...companies]
    const id = newCompanies.map(el => el.id).indexOf(companyIdToDelete)
    newCompanies.splice(id, 1)
    return newCompanies
}

const updateCompany = (companies, companyIdToUpdate) => {
    let newCompanies = [...companies]
    const id = newCompanies.map(el => el.id).indexOf(companyIdToUpdate.id)
    newCompanies[id] = companyIdToUpdate
    return newCompanies
}
const INIAT_STATE = {
    companies: [],
    loading: false,
    deleted: false
}
const company = (state = INIAT_STATE, action) => {

    switch (action.type) {
        case GET_COMPANY_REQUEST:
            return { loading: true }
        case GET_COMPANY_SUCCESS:
            return { loading: false, companies: action.payload }
        case GET_COMPANY_FAIL:
            return { loading: false }
        case GET_COMPANY_ID_REQUEST:
            return { loading: true }
        case GET_COMPANY_ID_FAIL:
            return { loading: false }
        case ADD_COMPANY_REQUEST:
            return { ...state, loading: true }
        case ADD_COMPANY_FAIL:
            return { loading: false }
        case DELETE_COMPANY_REQUEST:

            return { ...state, loading: true }
        case DELETE_COMPANY_SUCCESS:
            return { loading: false, deleted: true, companies: deleteCompany(state.companies, action.id) }
        case DELETE_COMPANY_FAIL:
            return { loading: false, deleted: false }
        case UPDATE_COMPANY_REQUEST:

            return { ...state, loading: true }
        // case UPDATE_COMPANY_SUCCESS:
        //     return { loading: false, companies: updateCompany(state.companies, action.payload) }
        case UPDATE_COMPANY_FAIL:
            return { loading: false }
        default:
            return state

    }
}
export default company