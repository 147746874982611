import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,

} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	signOutSuccess,

} from "../actions/Auth";

import axios from 'axios';
import { getMe } from 'redux/actions/CurrentUserProfile';


const signInEmailRequest = async (email, password) => {

	try {
		const user = await axios.post(`auth/login`, { email, password })
		return user.data
	} catch (err) {
		throw err
	}
}

export function* signIn() {
	yield takeEvery(SIGNIN, function* ({ payload }) {
		const { email, password } = payload;
		try {
			const user = yield call(signInEmailRequest, email, password);
			localStorage.setItem(AUTH_TOKEN, user.data.access_token)
			yield put(authenticated(user.data.access_token));
			yield put(getMe())
		} catch (err) {
			const message = err.response.data.errors[0].message
			yield put(showAuthMessage(message));
		}
	});
}

export function* signOut() {

	yield takeEvery(SIGNOUT, function* () {

		localStorage.removeItem(AUTH_TOKEN);
		yield put(signOutSuccess());


	});
}





export default function* rootSaga() {
	yield all([
		fork(signIn),
		fork(signOut),
	]);
}
