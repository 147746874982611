
import {
  GET_SEASON_REMISE_REQUEST,
  GET_SEASON_REMISE_SUCCESS,
  GET_SEASON_REMISE_FAIL,
  GET_ALL_SEASONS_REMISE,
  GET_ALL_SEASONS_REMISE_SUCCESS,
  ADD_SEASON_REMISE_REQUEST,
  ADD_SEASON_REMISE_SUCCESS,
  ADD_SEASON_REMISE_FAIL,
  GET_SEASON_REMISE_ID_REQUEST,
  GET_SEASON_REMISE_ID_SUCCESS,
  GET_SEASON_REMISE_ID_FAIL,
  DELETE_SEASON_REMISE_REQUEST,
  DELETE_SEASON_REMISE_SUCCESS,
  DELETE_SEASON_REMISE_FAIL,
  UPDATE_SEASON_REMISE_REQUEST,
  UPDATE_SEASON_REMISE_SUCCESS,
  UPDATE_SEASON_REMISE_FAIL
} from '../constants/Season';


export const getSeasonRemise = (page, filter) => {
  return {
    type: GET_SEASON_REMISE_REQUEST,
    page,
    filter
  };
};

export function getSeasonRemiseSuccess(season) {
  return {
    type: GET_SEASON_REMISE_SUCCESS,
    payload: season
  };
};

export const getSeasonRemiseFail = () => {
  return {
    type: GET_SEASON_REMISE_FAIL
  };
};

export const getCompany = (season) => {
  return {
    type: GET_SEASON_REMISE_ID_REQUEST,
    payload: season
  };
};

export function getCompanySuccess(season) {
  return {
    type: GET_SEASON_REMISE_ID_SUCCESS,
    payload: season
  };
};

export const getCompanyFail = () => {
  return {
    type: GET_SEASON_REMISE_ID_FAIL
  };
};

export function addSeasonRemise(season) {
  return {
    type: ADD_SEASON_REMISE_REQUEST,
    payload: season
  };
};

export function addSeasonRemiseSuccess(season) {
  return {
    type: ADD_SEASON_REMISE_SUCCESS,
    payload: season
  };
};

export const addSeasonRemiseFail = () => {
  return {
    type: ADD_SEASON_REMISE_FAIL
  };
};

export function addAllSeasonRemise(season) {
  return {
    type: ADD_SEASON_REMISE_REQUEST,
    payload: season
  };
};

export function addAllSeasonRemiseSuccess(season) {
  return {
    type: ADD_SEASON_REMISE_SUCCESS,
    payload: season
  };
};

export const addAllSeasonRemiseFail = () => {
  return {
    type: ADD_SEASON_REMISE_FAIL
  };
};

export function deleteSeasonRemise(season) {
  return {
    type: DELETE_SEASON_REMISE_REQUEST,
    id: season
  };
};

export function deleteSeasonRemiseSuccess(season) {
  return {
    type: DELETE_SEASON_REMISE_SUCCESS,
    id: season.id
  };
};

export const deleteSeasonRemiseFail = () => {
  return {
    type: DELETE_SEASON_REMISE_FAIL
  };
};

export function updateSeasonRemise(season) {
  return {
    type: UPDATE_SEASON_REMISE_REQUEST,
    payload: season
  };
};

export function updateSeasonRemiseSuccess(season) {

  return {
    type: UPDATE_SEASON_REMISE_SUCCESS,
    payload: season
  };
};

export const updateSeasonRemiseFail = () => {
  return {
    type: UPDATE_SEASON_REMISE_FAIL
  };
};

export const getAllSeasonRemise = () => {
  return {
    type: GET_ALL_SEASONS_REMISE
  };
};

export const getAllSeasonRemiseSuccess = (seasons) => {
  return {
    type: GET_ALL_SEASONS_REMISE_SUCCESS,
    payload: seasons
  }
};