
import {
    GET_ROLES,
    GET_ROLES_SUCCESS,
    ADD_NEW_ROLE
} from '../constants/Role';

const initState = {
    loading: false,
    roles: null
}

const roles = (state = initState, action) => {
    switch (action.type) {
        case GET_ROLES:
            return {
                ...state,
                loading: true
            }
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.payload
            }
        case ADD_NEW_ROLE:
            return {
                ...state,
                loading: false,
                roles: [action.payload, ...state.roles]
            }
        default:
            return state;
    }
}

export default roles