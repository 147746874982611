import {
  GET_BRANDS,
  DELETE_BRAND_REQUEST,
  DELETE_BRAND_SUCCESS,
  GET_BRANDS_SUCCESS,
  DELETE_BRAND_FAIL,
  ADD_NEW_BRAND
} from "redux/constants/brands";



export const getBrands = (page, filters, limit) => {
  return {
    type: GET_BRANDS,
    page,
    filters,
    limit
  };
};

export const getBrandsSuccess = (brands) => {
  return {
    type: GET_BRANDS_SUCCESS,
    payload: brands
  }
};
export const updateBrand = (brand) => {
  return {
    type: ADD_NEW_BRAND,
    payload: brand
  }
};
export function deleteBrandAction(brand) {
  return {
    type: DELETE_BRAND_REQUEST,
    payload: brand
  };
};
export function deleteBrandSuccess(id) {
  return {
    type: DELETE_BRAND_SUCCESS,
    id
  };
};

export const deleteBrandfail = () => {
  return {
    type: DELETE_BRAND_FAIL
  };
};
