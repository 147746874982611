import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import {
  GET_SELLERS,
  ADD_NEW_SELLER,
  DELETE_SELLER_REQUEST
} from '../constants/sellers';
import {
  getSellerSuccess,
  addNewSellerSuccess,
  deleteSellerSuccess,
  deleteSellerFail
} from "../actions/Seller";
import services from 'services';



export function* GetSeller() {

  yield takeLatest(GET_SELLERS, function* ({ page, filter }) {

    try {

      const data = yield call(services.getSellerData, page, filter);

      const fomattedData = data

      yield put(getSellerSuccess(fomattedData))

    } catch (err) {

    }

  });
}

export function* addSeller() {
  yield takeEvery(ADD_NEW_SELLER, function* ({ payload }) {
    try {
      const seller = yield call(services.addSellerData, payload);
      yield put(addNewSellerSuccess(seller));

    } catch (err) {
    }
  });
}



export function* deleteSeller() {
  yield takeEvery(DELETE_SELLER_REQUEST, function* ({ payload }) {

    try {
      const seller = yield call(services.deleteSellerAPI, payload);
      yield put(deleteSellerSuccess(seller));

    } catch (err) {
      yield put(deleteSellerFail(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(GetSeller),
    fork(addSeller),
    fork(deleteSeller)
  ]);
}
