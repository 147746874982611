import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { useSelector } from "react-redux";
import navigationConfig from "configs/NavigationConfig";
import { GROUP_3 } from 'constants/RolesConstants'

export const AppViews = () => {

  const { profile } = useSelector(state => state.currentUser)
  const role = profile?.role.group; //Get Role after from redux
  const roles = profile?.role.name; //Get Role after from redux

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {navigationConfig.map((page) => page.submenu.length == 0 && (page.roles.includes(role) || page?.roles.includes(roles)) ? (<Route exact key={page.key} path={`${page.path}`} component={lazy(() => import(`${page.componentPath}`))} />) : null)}
        {navigationConfig.map((pages) => pages.submenu.length > 0 && pages.submenu?.map((page) => page.roles.includes(role) ? (<Route exact key={page.key} path={`${page.path}`} component={lazy(() => import(`${page.componentPath}`))} />) : null))}
        {role === GROUP_3 ? <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/purchases`} /> : <Redirect exact from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />}
        {/* <Route path="*" component={lazy(() => import(`./home/index.js`))} ></Route> */}
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);