
import {
    GET_SEASON_REMISE_REQUEST,
    GET_SEASON_REMISE_SUCCESS,
    GET_SEASON_REMISE_FAIL,
    GET_ALL_SEASONS_REMISE,
    GET_ALL_SEASONS_REMISE_SUCCESS,
    GET_SEASON_REMISE_ID_REQUEST,
    GET_SEASON_REMISE_ID_SUCCESS,
    GET_SEASON_REMISE_ID_FAIL,
    ADD_SEASON_REMISE_REQUEST,
    ADD_SEASON_REMISE_SUCCESS,
    ADD_SEASON_REMISE_FAIL,
    DELETE_SEASON_REMISE_REQUEST,
    DELETE_SEASON_REMISE_SUCCESS,
    DELETE_SEASON_REMISE_FAIL,
    UPDATE_SEASON_REMISE_REQUEST,
    UPDATE_SEASON_REMISE_SUCCESS,
    UPDATE_SEASON_REMISE_FAIL
} from '../constants/Season';

const deleteCompany = (seasons, seasonIdToDelete) => {
    const newCompanies = [...seasons]
    const id = newCompanies.map(el => el.id).indexOf(seasonIdToDelete)
    newCompanies.splice(id, 1)
    return newCompanies
}

const updateCompany = (seasons, seasonIdToUpdate) => {
    let newCompanies = [...seasons]
    const id = newCompanies.map(el => el.id).indexOf(seasonIdToUpdate.id)
    newCompanies[id] = seasonIdToUpdate
    return newCompanies
}

const season = (state = { seasons: null, allSeasonRemise: null, loading: false, count: 0 }, action) => {

    switch (action.type) {
        case GET_SEASON_REMISE_REQUEST:
            return { loading: true }
        case GET_SEASON_REMISE_SUCCESS:
            return { loading: false, seasons: action.payload.data, count: action.payload.meta.filter_count }
        case GET_SEASON_REMISE_FAIL:
            return { loading: false }
        case GET_SEASON_REMISE_ID_REQUEST:
            return { loading: true }
        case GET_SEASON_REMISE_ID_SUCCESS:
            return { loading: false, seasons: [action.payload] }
        case GET_SEASON_REMISE_ID_FAIL:
            return { loading: false }
        case ADD_SEASON_REMISE_REQUEST:
            return { ...state, loading: true }
        case ADD_SEASON_REMISE_SUCCESS:
            return { ...state, loading: false, seasons: [...state.seasons, action.payload] }
        case ADD_SEASON_REMISE_FAIL:
            return { loading: false }
        case DELETE_SEASON_REMISE_REQUEST:
            return { ...state, loading: true }
        case DELETE_SEASON_REMISE_SUCCESS:
            return { loading: false, seasons: deleteCompany(state.seasons, action.id) }
        case DELETE_SEASON_REMISE_FAIL:
            return { loading: false }
        case UPDATE_SEASON_REMISE_REQUEST:
            return { ...state, loading: true }
        case UPDATE_SEASON_REMISE_SUCCESS:
            return { loading: false, seasons: updateCompany(state.seasons, action.payload) }
        case UPDATE_SEASON_REMISE_FAIL:
            return { loading: false }
        case GET_ALL_SEASONS_REMISE_SUCCESS:
            return { ...state, loading: false, allSeasonRemise: action.payload?.data }
        default:
            return state

    }
}
export default season