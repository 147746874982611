import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import {
    GET_COMPANY_REQUEST,
    ADD_COMPANY_REQUEST,
    DELETE_COMPANY_REQUEST,
    UPDATE_COMPANY_SUCCESS,
    ADD_COMPANY_SUCCESS,
    DELETE_COMPANY_SUCCESS
} from '../constants/Company';
import {
    getCompaniesSuccess,
    getCompaniesFail,
    addCompaniesSuccess,
    addCompaniesFail,
    deleteCompaniesSuccess,
    deleteCompaniesFail,
} from "../actions/Company";
import services from 'services';

export function* getAllCompanies() {
    yield takeLatest([GET_COMPANY_REQUEST, ADD_COMPANY_SUCCESS, DELETE_COMPANY_SUCCESS, UPDATE_COMPANY_SUCCESS], function* () {
        try {
            const data = yield call(services.getCompanies);
            yield put(getCompaniesSuccess(data.data));

        } catch (err) {
            yield put(getCompaniesFail(err));
        }
    });
}



export function* addAllCompanies() {
    yield takeEvery(ADD_COMPANY_REQUEST, function* ({ payload }) {
        try {
            const company = yield call(services.addCompanies, payload);
            yield put(addCompaniesSuccess(company.data));

        } catch (err) {
            yield put(addCompaniesFail(err));
        }
    });
}

export function* deleteCompanies() {
    yield takeEvery(DELETE_COMPANY_REQUEST, function* ({ payload }) {

        try {
            const user = yield call(services.deleteCompaniesAPI, payload);
            yield put(deleteCompaniesSuccess(user));

        } catch (err) {
            yield put(deleteCompaniesFail(err));
        }
    });
}



export default function* rootSaga() {
    yield all([
        fork(getAllCompanies),
        fork(addAllCompanies),
        fork(deleteCompanies)
    ]);
}
