

import {

  GET_COLLABORATOR_GROUP_LIST_SUCESS,
  GET_COLLABORATOR_GROUP_LIST,
  ADD_NEW_COLLABORATOR_GROUP,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL
} from '../constants/CollaboratorGroup';
export const getCollaboratorGroups = () => {
  return {
    type: GET_COLLABORATOR_GROUP_LIST
  };
};

export const getCollaboratorGroupsSucess = (data) => {
  return {
    type: GET_COLLABORATOR_GROUP_LIST_SUCESS,
    payload: data
  };
};

export const addNewCollaboratorGroups = (data) => {
  return {
    type: ADD_NEW_COLLABORATOR_GROUP,
    payload: data
  }
};

export function deleteCategoryAction(category) {
  return {
    type: DELETE_CATEGORY_REQUEST,
    payload: category
  };
};
export function deleteCategorySuccess(company) {
  return {
    type: DELETE_CATEGORY_SUCCESS,
    id: company.id
  };
};

export const deleteCategoryFail = () => {
  return {
    type: DELETE_CATEGORY_FAIL
  };
};
