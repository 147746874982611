import { GET_CLIENTS_SUCCESS, GET_CLIENTS, GET_ALL_CLIENTS, GET_ALL_CLIENTS_SUCCESS } from "redux/constants/Clients";

export const getClients = (page, filter = undefined) => {
  return {
    type: GET_CLIENTS,
    page,
    filter
  };
};

export const getClientsSuccess = (clients) => {
  return {
    type: GET_CLIENTS_SUCCESS,
    payload: clients
  }
};
export const getAllClients = (page, filter = undefined) => {
  return {
    type: GET_ALL_CLIENTS,
    page,
    filter
  };
};

export const getAllClientsSuccess = (clients) => {
  return {
    type: GET_ALL_CLIENTS_SUCCESS,
    payload: clients
  }
};