import { GET_ROLES, GET_ROLES_SUCCESS, ADD_NEW_ROLE } from "redux/constants/Role";

export const getRoles = () => {
    return {
        type: GET_ROLES
    };
};

export const getRoleSuccess = (roles) => {
    return {
        type: GET_ROLES_SUCCESS,
        payload: roles
    }
};
export const addNewRole = (role) => {
    return {
        type: ADD_NEW_ROLE,
        payload: role
    }
};