import axios from 'axios';
import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';

import {
    DELETE_CATEGORY_REQUEST,
    GET_COLLABORATOR_GROUP_LIST,
    DELETE_CATEGORY_SUCCESS
} from '../constants/CollaboratorGroup';
import {
    deleteCategorySuccess,
    deleteCategoryFail,
    getCollaboratorGroupsSucess
} from "../actions/CollaboratorGroups";
import services from 'services';

export function* getCollaboratorGroupsList() {


    yield takeLatest([GET_COLLABORATOR_GROUP_LIST, DELETE_CATEGORY_SUCCESS], function* () {
        try {
            const CollaboratorGroup = yield call(services.getCollaboratorGroupsData);

            yield put(getCollaboratorGroupsSucess(CollaboratorGroup.data));

        } catch (error) {

        }
    });
}

export function* deleteCategory() {
    yield takeEvery(DELETE_CATEGORY_REQUEST, function* ({ payload }) {

        try {
            const user = yield call(services.deleteCategoryAPI, payload);
            yield put(deleteCategorySuccess(user));

        } catch (err) {
            yield put(deleteCategoryFail(err));
        }
    });
}
export default function* rootSaga() {
    yield all([
        fork(getCollaboratorGroupsList),
        fork(deleteCategory)

    ]);
}
