import { GET_GUEST, GET_GUEST_SUCCESS, ADD_NEW_GUEST, ADD_NEW_GUEST_SUCCESS, DELETE_GUEST_REQUEST, DELETE_GUEST_SUCCESS } from "redux/constants/Guest";

export const getGuest = (page, filter) => {
  return {
    type: GET_GUEST,
    page,
    filter
  };
};

export const getGuestsSuccess = (guests) => {
  return {
    type: GET_GUEST_SUCCESS,
    payload: guests
  }
};

export const addNewGuest = (guest) => {
  return {
    type: ADD_NEW_GUEST,
    payload: guest
  }
};

export function addNewGuestSuccess(guest) {
  return {
    type: ADD_NEW_GUEST_SUCCESS,
    payload: guest
  };
};

export function deleteGuestAction(guest) {
  return {
    type: DELETE_GUEST_REQUEST,
    payload: guest
  };
};
export function deleteGuestSuccess(id) {
  return {
    type: DELETE_GUEST_SUCCESS,
    id
  };
};