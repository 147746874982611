import React, { useState, useEffect } from 'react'

import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut, MeAction } from 'redux/actions/Auth';
import Utils from 'utils';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import AnonymeAvatar from 'assets/svg/anonymeAvatar.svg'

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/"
  },

  {
    title: "Account Setting",
    icon: SettingOutlined,
    path: "/"
  },
  {
    title: "Billing",
    icon: ShopOutlined,
    path: "/"
  },
  {
    title: "Help Center",
    icon: QuestionCircleOutlined,
    path: "/"
  }
]


const addDefaultSrc = (ev) => {
  ev.target.src = AnonymeAvatar
}

export const NavProfile = ({ signOut }) => {
  const profileImg = "/img/avatars/avatarr.png";
  const { profile } = useSelector(state => state.currentUser)
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          {!profile?.avatar ? <Avatar size={45} src={profileImg} /> :
            <img src={Utils.getAssetFullUrl(profile?.avatar)} onError={addDefaultSrc} width={35} height={35} style={{
              borderRadius: "50%",
              objectFit: "contain"
            }} />}
          <div className="pl-3">
            <h4 className="mb-0">{profile?.first_name} {profile?.last_name}</h4>
            <span className="text-muted">{profile?.role.name}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>

          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">{Utils.setLocale("logout")}</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          {!profile?.avatar ? <Avatar size={45} src={profileImg} /> :
            <img src={Utils.getAssetFullUrl(profile?.avatar)} onError={addDefaultSrc} width={35} height={35} style={{
              borderRadius: "50%",
              objectFit: "contain"
            }} />}
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, { signOut })(NavProfile)